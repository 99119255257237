import React from 'react'
import * as st from '~/assets/styl/404.module.styl'

import { Link } from 'gatsby'

import galleryBg from '~/assets/img/gallery-bg.jpg'

const NotFound = () => (
  <>
    <section
      className={st.notFoundWrapper}
      style={{ backgroundImage: `url(${galleryBg})` }}
    >
      <div>
        <h1>Ops! Página não encontrada</h1>
        <p>
          <strong>Percebemos que ocorreu um erro 404.</strong>
        </p>
        <p>
          A página que vopcê tentou acessar não existe ou está fora do
          <br /> ar temporariamente.
        </p>
      </div>
      <Link to="/" className={st.back}>
        Ir para a home
      </Link>
    </section>
  </>
)

export default NotFound
